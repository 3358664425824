import { get, post, put, postImage, remove, getpdf } from '../helpers/https';
import { WEB_URL } from 'helpers/urlConstant';
import { EMAIL_URL } from 'helpers/urlConstant';
import { update } from 'lodash';

/******** USER ROUTE*********/
export const addUser = (data) => {
    return post(`${WEB_URL}/employee/adduser`, data);
};
export const loginUser = (data) => {
    return post(`${WEB_URL}/employee/login`, data);
};

export const getUser = (id, data) => {
    return get(`${WEB_URL}/employee/getbyid/${id}`, data);
};

export const getUserList = (data) => {
    return get(`${WEB_URL}/employee/getlist`, data);
};

export const updateUser = (userId, data) => {
    return post(`${WEB_URL}/employee/update/${userId} `, data);
};

export const deleteUser = (id, data) => {
    return remove(`${WEB_URL}/employee/delete/${id}`, data);
};

export const changePassword = (data) => {
    return post(`${WEB_URL}/users/password`, data);
};

export const userExist = (data) => {
    return post(`${WEB_URL}/employee/exist`, data);
};
export const updatePassword = (userId, data) => {
    return post(`${WEB_URL}/employee/update-password/${userId}`, data);
};

export const addCostumer = (data) => {
    return post(`${WEB_URL}/costumer/addcostmer`, data);
};
export const updateCostumer = (id, data) => {
    return post(`${WEB_URL}/costumer/updatecostmer/${id}`, data);
};

export const getCostumerlist = (data) => {
    return get(`${WEB_URL}/costumer/getlist`, data);
};
export const deleteCostumer = (id, data) => {
    return remove(`${WEB_URL}/costumer/delete/${id}`, data);
};

export const getProductlist = (data) => {
    return get(`${WEB_URL}/product/getlist`, data);
};
export const deleteProduct = (id, data) => {
    return remove(`${WEB_URL}/product/delete/${id}`, data);
};
export const addOrder = (data) => {
    return post(`${WEB_URL}/order/addorder`, data);
};

export const addOrderreportdate = (data) => {
    return post(`${WEB_URL}/order/getorder`, data);
};

export const getOrder = (data) => {
    return get(`${WEB_URL}/order/getlist`, data);
};
export const addProduct = (data) => {
    return postImage(`${WEB_URL}/product/addproduct`, data);
};
export const updateProduct = (productId, data) => {
    return post(`${WEB_URL}/product/update/${productId}`, data);
};

export const Barcode = (data) => {
    return post(`${WEB_URL}/barcode/add`, data);
};

export const UpdateBarcode = (data) => {
    return post(`${WEB_URL}/barcode/update`, data);
};
export const Filterdata = (data) => {
    return post(`${WEB_URL}/barcode/add-fields`, data);
};

export const updateOrder = (data) => {
    return post(`${WEB_URL}/order/update`, data);
};

export const deleteOrder = (orderId, data) => {
    return remove(`${WEB_URL}/order/delete/${orderId}`, data);
};

export const getSubscriptionsDetails = (id, data) => {
    return remove(`${WEB_URL}/order/delete/${id}`, data);
};

export const addMoulder = (data) => {
    return post(`${WEB_URL}/mould/addmoulder`, data);
};

export const updateMoulder = (id, data) => {
    return post(`${WEB_URL}/mould/update-moulder/${id}`, data);
};
export const getMoulder = (data) => {
    return get(`${WEB_URL}/mould/get-moulder`, data);
};

export const deleteMoulder = (id, data) => {
    return remove(`${WEB_URL}/mould/delete-moulder/${id}`, data);
};

export const getChild = (data) => {
    return get(`${WEB_URL}/product/getchildlist`, data);
};

export const getItemList = (data) => {
    return post(`${WEB_URL}/mould/get-itemlist`, data);
};
export const getSandList = (data) => {
    return get(`${WEB_URL}/sand/get-sand`, data);
};
export const addSand = (data) => {
    return post(`${WEB_URL}/sand/addsand`, data);
};
export const updateSand = (id, data) => {
    return post(`${WEB_URL}/sand/update-sand/${id}`, data);
};

export const getSandDateList = (data) => {
    return post(`${WEB_URL}/sand/get-itemlist`, data);
};
export const addPouring = (data) => {
    return post(`${WEB_URL}/pouring/add`, data);
};
export const updatePouring = (id, data) => {
    return post(`${WEB_URL}/pouring/update/${id}`, data);
};
export const getPouring = (data) => {
    return get(`${WEB_URL}/pouring/get`, data);
};
export const addCasting = (data) => {
    return post(`${WEB_URL}/casting/add`, data);
};
export const updateCasting = (id, data) => {
    return post(`${WEB_URL}/casting/update/${id}`, data);
};
export const getCasting = (data) => {
    return get(`${WEB_URL}/casting/get`, data);
};

export const getPouringDateList = (data) => {
    return post(`${WEB_URL}/pouring/getdatelist`, data);
};

export const getCastingDateList = (data) => {
    return post(`${WEB_URL}/casting/getdate`, data);
};

export const addMoulderDetails = (data) => {
    return post(`${WEB_URL}/mould/add-moulder-details`, data);
};

export const getMoulderDetails = (data) => {
    return get(`${WEB_URL}/mould/get-moulder-details`, data);
};

export const getMoulderWiseDetails = (data) => {
    return post(`${WEB_URL}/mould/get-itemlistformoulder`, data);
};

export const addInventory = (data) => {
    return post(`${WEB_URL}/inventory/add`, data);
};

export const getInventory = (data) => {
    return get(`${WEB_URL}/inventory/get`, data);
};

export const updateInventory = (id, data) => {
    return post(`${WEB_URL}/inventory/update/${id}`, data);
};
export const deleteInventory = (id, data) => {
    return remove(`${WEB_URL}/inventory/delete/${id}`, data);
};

export const getInventoryDateList = (data) => {
    console.log('api Payload', data);
    return post(`${WEB_URL}/inventory/getdatelist`, data);
};

export const deletePouring = (id, data) => {
    return remove(`${WEB_URL}/pouring/delete/${id}`, data);
};
export const deleteCasting = (id, data) => {
    return remove(`${WEB_URL}/casting/delete/${id}`, data);
};

export const getInventoryRecord = (data) => {
    return post(`${WEB_URL}/inventory/getinventory`, data);
};

export const itemExist = (data) => {
    return post(`${WEB_URL}/inventory/item-exist`, data);
};

export const getSingleMoulderDate = (data) => {
    return post(`${WEB_URL}/mould/get-singledatelist`, data);
};

export const getRole = (data) => {
    return get(`${WEB_URL}/role/get-role`, data);
};

export const addRole = (data) => {
    return post(`${WEB_URL}/role/addrole`, data);
};

export const updateRole = (id, data) => {
    return post(`${WEB_URL}/role/update-role/${id}`, data);
};
export const deleteRole = (id, data) => {
    return remove(`${WEB_URL}/role/delete-role/${id}`, data);
};

export const updateMoulderdata = (id, data) => {
    return post(`${WEB_URL}/mould/update/${id}`, data);
};

export const deleteMoulderdata = (id, data) => {
    return remove(`${WEB_URL}/mould/delete/${id}`, data);
};
